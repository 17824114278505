@media (max-width: 720px) {

    .mo {
        display: block !important;
    }
    .do {
        display: none !important;
    }

    .App {
        max-width: 100vh;
    }

    .Headline {
        padding: 6px;

        .heading {
            font-size: 27pt;
        }
        .heading-good {
            font-size: 41pt;
        }

        .subheading {
            max-width: 330px;
            font-size: 15pt;
        }
        
        .subheading-profits {
            width: auto;
            max-width: 330px;
            margin-left: 0px;

            margin-top: 100px;

            font-size: 12pt;
        }

        .icon-position {
            .flex-icon-img {
                width: 120px;
                left: 310px;
                top: -80px;
            }
            .scream-icon-img {
                display: none;
            }
        }
    }

    .BrowserShot-position {
        display: none;
    }

    .BrowserShot {
        left: 40px;
        top: 540px;

        .browser-img {
            width: 480px;
        }
    }
}

.mo {
    display: none;
}
.do {
    display: block;
}