body {
    background-color: lighten(#fff, 4%);
    background-color: #fff;
    font-family: "Mulish" !important;
}

.App-header {
    width: 92%;
    max-width: 1160px;
    margin: 40px auto;

    display: flex;
}

.App-main {
    width: 92%;
    max-width: 1160px;
    margin: 40px auto;
}

.Logo {
    .text {
        font-size: 19pt;
        font-weight: 900;
        letter-spacing: -0.5px;
        color: #111;
    }

    .logo-img {
        width: 48px;
        margin-left: 4px;
        //opacity: 0.25;
    }
}
.SignIn {
    cursor: pointer;
    margin-left: auto;
    padding-top: 16px;
    font-weight: 700;
}
.SignIn:hover {
    text-decoration: underline;
}

.person-bk {
    position: absolute;
    top: 220px;
    left: 261px;
    width: 690px;
    z-index: -1;
    opacity: 0.9;
}

.SubSignUp {
    width: 500px;
}

.Register, .SignInPage, .SubSignUp {
    display: none;
    transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;

    .backfill {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: #fff3ed;
        //opacity: 0.1;
        z-index: 100;
        transition: opacity .25s ease-in-out;
        -moz-transition: opacity .25s ease-in-out;
        -webkit-transition: opacity .25s ease-in-out;
    }

    .modal {
        display: flex;
        flex-direction: column;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 380px;
        height: 520px;
        background: #fff;
        border-radius: 20px;
        z-index: 200;

        padding-top: 50px;

        border: 1px solid #ddd;

        box-shadow: 7px 5px 47px -11px rgba(0,0,0,0.25);
-webkit-box-shadow: 7px 5px 47px -11px rgba(0,0,0,0.25);
-moz-box-shadow: 7px 5px 47px -11px rgba(0,0,0,0.25);
    }

    .heading {
        padding: 0.25em 0;
        font-size: 24pt;
        font-weight: 800;
        letter-spacing: -1px;
        text-align: center;
    }
    .form-heading {
        padding-top: 18px;
        padding-bottom: 8px;
        font-size: 13pt;
        font-weight: 700 !important;
        letter-spacing: -0.5px;
        text-align: left;
    }
    .email-only {
        padding: 30px 0 0 40px;
        font-weight: 500;
        //text-align: left;
    }

    .form {
        width: 300px;
    }

    .form-error {
        padding: 20px 40px 0 40px;
        font-weight: 500;
        text-align: center;
        color: red;
    }

    .input-text {
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 12px;
        padding: 1em 1em 1em 3em;
        font-size: 11.5pt;
        background: #fff;
        color: #555;
        border: 1px solid #ddd;
        border-radius: 3px;
        font-size: 12pt;
        outline: none;
        font-family: 'Mulish';
    }
    .input-text:focus {
        border: 1px solid #aaa;
        color: #222;
    }

    .submit-button {
        border: 0;
        width: 100%;
        margin-top: 2px;
        padding: 16px 0 16px 0;
        font-size: 12pt;
        font-weight: 700;
        color: #fff;
        background-color: #222;
        border-radius: 100px;
        text-align: center;
        font-family: 'Mulish';
    }

    .terms {
        margin-top: auto;
        margin-top: 40px;
        border-top: 1px solid #e3e3e3;
        padding: 16px 0 28px 0;
        font-size: 10pt;
        color: #666;
        letter-spacing: 0px;
        text-align: left;
    }
}

.SignInPage {
    .subheading {
        padding: 20px 0;
        text-align: center;
    }
    .form {
        margin-left: auto;
        margin-right: auto;
    }
}

.BrowserShot-position {
    position: absolute;
    width: 300px;
    height: 200px;
    top: -16px;
}

.BrowserShot {
    position: fixed;
    right: -180px;
    top: 120px;

    .browser-img {
        width: 840px;
        height: 512px;

        background-size: cover;
        
        border-radius: 12px;
        box-shadow: 6px 10px 77px -15px rgba(0,0,0,0.45);
-webkit-box-shadow: 6px 10px 77px -15px rgba(0,0,0,0.45);
-moz-box-shadow: 6px 10px 77px -15px rgba(0,0,0,0.45);
    }
}


.Headline {
    padding-top: 28px;

    .heading {
        max-width: 560px;
        font-size: 33pt;
        font-weight: 600;
        letter-spacing: -1.5px;
        line-height: 1.15em;
        color: #564a44;
        color: #f56620;
    }
    .heading-bad {
        font-weight: 900;
        color: lighten(#f82b16, 0%);
        text-decoration: underline;
    }
    .heading-good {
        font-size: 59pt;
        font-weight: 900;
        text-decoration: underline;
        color: #00df69;
    }

    .icon-position {
        position: absolute;

        .flex-icon-img {
            position: relative;

            opacity: 0;
            width: 106px;
            top: -162px;
            left: 438px;

            // top: -36px;
            // left: 390px;
        }
        .scream-icon-img {
            opacity: 0;
            position: relative;
            width: 70px;
            top: -152px;
            left: -76px;        
        }
    }

    .subheading {
        line-height: 1.5em;
        padding-top: 16px;
        width: 600px;
        font-weight: 400;
        font-size: 15pt;
        letter-spacing: -.5px;
    }
    .subheading-profits {
        //background: #fff;
        line-height: 1.5em;
        padding-top: 8px;
        width: 480px;
        font-weight: 400;
        font-size: 13.5pt;
        //letter-spacing: -.5px;
        color: #111;
        //font-style: italic;

        background: #ffe6b6;
        margin-top: 76px;
        margin-left: -16px;
        padding: 16px;
        border-radius: 20px;
        border: 0px solid #fff;

        background: rgb(255,216,111);
        background: rgb(255,227,151);
        background: linear-gradient(0deg, rgba(255,237,151,1) 0%, rgba(255,247,207,1) 100%);
    }
    .profits {
        font-weight: 700 !important;
        color: #111;
        //text-decoration: underline;
    }

    .cta {
        width: 180px;
        cursor: pointer;
        display: inline-block;
        margin-top: 24px;
        font-size: 15pt;
        font-weight: 800;
        background: #111;
        border-radius: 6px;
        padding-top: 17px;
        color: #fff;
        text-align: center;
    }

    .cta:hover {
        text-decoration: underline;
        background: #222 !important;
    }
}

.sub-cta {
    display: flex;

    .subheading {
        width: 510px;

        font-weight: 500;
        font-size: 18pt;
        padding-right: 12px;
    }

    .cta {
        height: 62px;
        box-sizing: border-box;

        background-color: #ffd79e;
        border: 4px solid lighten(#ffd79e, 7%);
        color: #2a2231;

        padding-top: 14px;
        border-radius: 40px;
        font-weight: 800;

        box-shadow: 10px 1px 12px 0px rgba(0,0,0,0.21);
-webkit-box-shadow: 10px 1px 12px 0px rgba(0,0,0,0.21);
-moz-box-shadow: 10px 1px 12px 0px rgba(0,0,0,0.21);

        background-color: #111;
        color: #fff;
        border: 4px solid #111;
    }

    .cta:hover {
        cursor: pointer;
        background-color: #e6551c !important;
        border: 4px solid #e6551c;
    }
}

.footer {
    width: 100%;
    max-width: 1200px;
    text-align: center;
    padding-top: 268px;
    padding-bottom: 10px;
    font-size: 10.5pt;
    color: #777;
}

.footer-link:link {
    color: #777;
}
.footer-link:hover {
    color: #777;
}
.footer-link:active {
    color: #777;
}



@media (max-width: 1370px) {

    .person-bk {
        top: 238px;
        left: 160px;
        width: 660px;
    }

    .BrowserShot {
        right: -200px;
    }
}

.person-bk {
    //opacity: 0.2;
}

@media (max-width: 1420px) {

    .person-bk {
        left: 210px;
        width: 670px;
    }

    .BrowserShot {
        right: -200px;
    }
}

@media (max-width: 1300px) {

    .person-bk {
        top: 238px;
        left: 138px;
        width: 620px;
    }

    .BrowserShot {
        right: -300px;
    }
}

@media (max-height: 760px) {
    .App-header {
        margin: 30px auto 10px auto;
    }

    .person-bk {
        top: 212px !important;
    }

    .BrowserShot {
        top: 120px !important;
    }
}

.person-bk {
    //opacity: 0.2;
}


@media (min-width: 1720px) {

    .App-header, .App-main {
        max-width: 1360px;
    }

    .App-main {
        margin-top: 80px;
    }

    .footer {
        padding-top: 180px;
    }

    .person-bk {
        top: 168px;
        left: 210px;
        width: 970px;
    }

    .BrowserShot {
        //left: 780px;

        .browser-img {
            width: 1000px !important;
            height: 692px;
        }
    }
}